import React from "react";
import cn from "classnames";
import Icon, { IconType } from "../Icon";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useTranslation } from "react-i18next";

interface TOCProps {
  type?: string;
  content?: any;
  tocHeadings?: any;
}
const TableOfContents: React.FC<TOCProps> = props => {
  const { type, content, tocHeadings } = props;
  const { language } = useI18next();
  const { t } = useTranslation();

  let toc;
  let atleastOneHeading = false;
  if (content != undefined && content != null && content != "") {
    const contentTOC = JSON.parse(content);
    console.log("contentTOC", contentTOC);
    toc =
      contentTOC &&
      contentTOC.content
        .map(item => {
          if (
            (item?.nodeType === "heading-2" ||
              item?.nodeType === "heading-3" ||
              item?.nodeType === "heading-4") &&
            item?.content[0]?.value != ""
          ) {
            atleastOneHeading = true;
            return { heading: item.content[0].value, level: item.nodeType };
          }
        })
        .filter(val => val !== undefined);
  }

  return (
    <>
      {atleastOneHeading && (
        <div
          className={cn(
            type == "legaldocument"
              ? "sticky lg:max-w-[240px] lg:mr-20"
              : "p-5 md:px-18 md:py-13 bg-gray-6 rounded-2xl mb-20"
          )}
        >
          <p
            className={cn(
              type == "legaldocument" ? "md:text-3xl" : "",
              "text-3xl md:text-4xl font-semibold text-indigo-3-dark flex justify-between items-center"
            )}
          >
            {t("Table of Contents")}
            {type !== "legaldocument" && (
              <Icon
                iconType={IconType.optimizeBid}
                className="md:w-[35px] md:h-[35px]"
              />
            )}
          </p>
          <div
            className={cn(
              type == "legaldocument" ? "sm:pl-0" : "",
              "flex flex-col mt-10"
            )}
          >
            {type == "legaldocument"
              ? toc &&
                toc?.map((title, idx) => {
                  return (
                    <>
                      {title?.heading !== "" && title?.level === "heading-2" && (
                        <a
                          key={idx}
                          href={`#${
                            language && language === "ja"
                              ? title?.heading?.replace(/[0-9\s]/g, "").toLowerCase()
                              : title?.heading
                                  ?.replace(/[^a-zA-Z]/g, "")
                                  .toLowerCase()
                          }`}
                          className="text-indigo-1 my-1"
                        >
                          <span className="hover:underline hover:text-indigo-2">
                            {title.heading}
                          </span>
                        </a>
                      )}
                      {title?.heading !== "" && title?.level === "heading-3" && (
                        <a
                          className="text-indigo-1 my-1"
                          href={`#${
                            language && language === "ja"
                              ? title?.heading?.replace(/[0-9\s]/g, "").toLowerCase()
                              : title?.heading
                                  ?.replace(/[^a-zA-Z]/g, "")
                                  .toLowerCase()
                          }`}
                        >
                          <span className="hover:underline hover:text-indigo-2">
                            {title.heading}
                          </span>
                        </a>
                      )}
                      {title?.heading !== "" && title?.level === "heading-4" && (
                        <a
                          className="text-indigo-1 my-1"
                          href={`#${
                            language && language === "ja"
                              ? title?.heading?.replace(/[0-9\s]/g, "").toLowerCase()
                              : title?.heading
                                  ?.replace(/[^a-zA-Z]/g, "")
                                  .toLowerCase()
                          }`}
                        >
                          <span className="hover:underline hover:text-indigo-2">
                            {title.heading}
                          </span>
                        </a>
                      )}
                    </>
                  );
                })
              : toc &&
                toc?.map((title, idx) => {
                  return (
                    <>
                      {tocHeadings?.includes("h2") &&
                        title?.heading !== "" &&
                        title?.level === "heading-2" && (
                          <a
                            key={idx}
                            href={`#${
                              language && language === "ja"
                                ? title?.heading?.replace(/[0-9\s]/g, "").toLowerCase()
                                : title?.heading
                                    ?.replace(/[^a-zA-Z]/g, "")
                                    .toLowerCase()
                            }`}
                            className="text-indigo-1 my-1"
                          >
                            <span className="hover:underline hover:text-indigo-2">
                              {title.heading}
                            </span>
                          </a>
                        )}
                      {tocHeadings?.includes("h3") &&
                        title?.heading !== "" &&
                        title?.level === "heading-3" && (
                          <li
                            style={{ listStyleType: "circle" }}
                            className="ml-4"
                          >
                            <a
                              className="text-indigo-1 my-1"
                              href={`#${
                                language && language === "ja"
                                  ? title?.heading?.replace(/[0-9\s]/g, "").toLowerCase()
                                  : title?.heading
                                      ?.replace(/[^a-zA-Z]/g, "")
                                      .toLowerCase()
                              }`}
                            >
                              <span className="hover:underline hover:text-indigo-2">
                                {title.heading}
                              </span>
                            </a>
                          </li>
                        )}
                      {tocHeadings?.includes("h4") &&
                        title?.heading !== "" &&
                        title?.level === "heading-4" && (
                          <li className="list-disc ml-8">
                            <a
                              className="text-indigo-1 my-1"
                              href={`#${
                                language && language === "ja"
                                  ? title?.heading?.replace(/[0-9\s]/g, "").toLowerCase()
                                  : title?.heading
                                      ?.replace(/[^a-zA-Z]/g, "")
                                      .toLowerCase()
                              }`}
                            >
                              <span className="hover:underline hover:text-indigo-2">
                                {title.heading}
                              </span>
                            </a>
                          </li>
                        )}
                    </>
                  );
                })}
          </div>
        </div>
      )}
    </>
  );
};

export default TableOfContents;

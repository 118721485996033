import { Link } from "gatsby";
import React from "react";
import * as styles from "./RelatedArticle.module.scss";
import RightArrow from "../../../images/right-arrow.svg";
import cn from "classnames";
import { convertBlogSlug } from "../../../utils/blog";
import { useI18next } from "gatsby-plugin-react-i18next";

interface RelatedArticleProps {
  publication: any;
}

const RelatedArticle: React.FC<RelatedArticleProps> = props => {
  const { publication } = props;

  const { t } = useI18next();

  return (
    <Link
      to={convertBlogSlug(publication.slug, publication.node_locale)}
      className={cn(styles.top_image_card, "group")}
    >
      <div className={styles.card_info}>
        <p className="text-xl font-semibold">{publication.title}</p>
        <div className={styles.footer}>
          <p className="flex justify-start items-center text-indigo-1 font-semibold">
            {t("Read")}{" "}
            <RightArrow className="ml-3 group-hover:rotate-90 transition transition-duration-300 ease-in" />
          </p>
        </div>
      </div>
    </Link>
  );
};

export default RelatedArticle;

import React from "react";
import SimpleCard from "../SimpleCard";
import ArrowRight from "../../../images/right-arrow.svg";
import { useState } from "react";

interface SimpleRecommendationsProps {
  title?: string;
  publications?: any[];
}

const SimpleRecommendations: React.FC<SimpleRecommendationsProps> = (
  props: any
) => {
  const { title, publications } = props;
  const [position, setPostion] = useState<number>(1);

  const getCurrentTranslate = () => {
    if (position === 1) {
      return `translateX(-0%)`;
    } else {
      return `translateX(-${(position - 1) * 100}%)`;
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center pb-10">
        <p className="text-3xl md:text-4xl gray-dark-4 font-semibold">
          {title}
        </p>
        <div className="flex items-center gap-6 lg:hidden">
          <button
            className="focus:outline-none"
            onClick={() => {
              if (position === 1) {
                setPostion(publications.length);
              } else {
                setPostion(position - 1);
              }
            }}
          >
            <ArrowRight className="text-indigo-1 rotate-180 relative top-[1px]" />
          </button>
          <button
            className="focus:outline-none"
            onClick={() => {
              if (position === publications.length) {
                setPostion(1);
              } else {
                setPostion(position + 1);
              }
            }}
          >
            <ArrowRight className="text-indigo-1" />
          </button>
        </div>
      </div>
      <div className="flex pb-10 -mx-6 md:-mx-4 overflow-hidden">
        {publications?.map((node, i) => {
          const publication = node?.node || node;
          return (
            <div
              key={i}
              className="transition transition-duration-200 ease-in ml-4 mr-0 lg:mx-4 min-w-[420px] lg:min-w-[auto] lg:w-1/3"
              style={{ transform: getCurrentTranslate() }}
            >
              <SimpleCard key={publication.id} publication={publication} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SimpleRecommendations;

import { Link } from "gatsby";
import React from "react";
import Icon, { IconType } from "../../Icon";
import { convertBlogSlug } from "../../../utils/blog";

interface SimpleCardProps {
  publication: any;
  color?: any;
}

const SimpleCard: React.FC<SimpleCardProps> = props => {
  const { publication, color } = props;

  const truncate = (str: string, n: number) => {
    return str?.length > n ? str?.substring(0, n - 1) + "..." : str;
  };

  return (
    <Link
      to={convertBlogSlug(publication.slug, publication.node_locale)}
      className="relative flex h-full bg-white rounded-xl p-8 group overflow-hidden hover:shadow-md"
    >
      <div className="relative z-10 flex flex-col justify-center">
        <div className="flex mb-2">
          <p className={`${color} text-sm font-semibold mr-4`}>
            {publication?.categories?.[0].name}
          </p>
          <p className="text-base font-medium">
            <span className="ml-auto flex items-center">
              {`${publication.timeToRead || 0} min`}
              <Icon iconType={IconType.clock} className="ml-1" />
            </span>
          </p>
        </div>
        <p className="font-semibold text-xl lg:text-lg">
          {truncate(publication.title, 120)}
        </p>
        <p className="text-sm bottom-0 mt-2 text-indigo-1 font-semibold">
          {publication.publishDate}
        </p>
      </div>
      <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-br from-blue-2 transition duration-200 ease-in to-purple-2 opacity-0 group-hover:opacity-30"></div>
    </Link>
  );
};

export default SimpleCard;

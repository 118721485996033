import { graphql, useStaticQuery } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import React from "react";
import SEO from "../index";

interface Props {
  data?: any;
  title?: string;
  description?: string;
  image?: string;
  robots?: string;
  locales: any;
}
const BlogSeo: React.FC<Props> = props => {
  const { data, title, description, image, robots, locales } = props;

  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "perpetua-logo.png" }) {
          childImageSharp {
            gatsbyImageData(width: 628, layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const articleBody = data?.body ? JSON.parse(data?.body.raw) : null;

  const articleString = articleBody?.content
    .map(section => {
      return section.content[0] ? section.content[0].value : "";
    })
    .join("");

  const authors =
    data?.authors?.length > 1
      ? data?.authors?.map(author => {
          return {
            "@type": "person",
            name: author.name
          };
        })
      : {
          "@type": "person",
          name: data?.authors ? data?.authors[0].name : "Perpetua Author"
        };

  const keywords = data?.categories
    ? data?.categories?.map(tag => tag.name)
    : "";

  const pageImage =
    data?.bannerImage && getSrc(data?.bannerImage)
      ? getSrc(data?.bannerImage)
      : undefined;

  const blogSchema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: data?.title,
    image: pageImage,
    publisher: {
      "@type": "Organization",
      name: "Perpetua",
      url: "https://perpetua.io/",
      logo: getSrc(logo)
    },
    url: `https://perpetua.io/${data?.convertedSlug}`,
    datePublished: data?.publishDate,
    dateCreated: data?.createdAt,
    dateModified: data?.updatedAt,
    description: data?.metaDescription || description,
    author: authors,
    articleSection: data?.categories ? data?.categories[0].name : "",
    abstract: data?.bodyPreview ? data?.bodyPreview : "",
    keywords: keywords,
    articleBody: articleString || data?.bodyPreview,
    mainEntityOfPage: data?.title || title,
    languagesAvailable: locales
  };

  return (
    <SEO
      schema={blogSchema}
      title={title || data?.title}
      description={description || data?.description}
      image={image || pageImage}
      robots={robots}
    />
  );
};

export default BlogSeo;
